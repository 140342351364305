import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-style-guide';
import { AccountSwitcherService } from 'Roblox';
import { dataStores } from 'core-roblox-utilities';
import authUtil from '../util/authUtil';

const { getSignupUrl } = authUtil;

function HeaderSignupLink({ translate }) {
  // use effect for get signupurl
  // eslint-disable-next-line no-undef
  const [
    isAccountSwitchingEnabledForBrowser
  ] = AccountSwitcherService?.useIsAccountSwitcherAvailableForBrowser() ?? [false];

  const handleSignupClick = () => {
    window.location.href = getSignupUrl(isAccountSwitchingEnabledForBrowser);
  };

  useEffect(() => {
    try {
      const {
        authIntentDataStore: { saveGameIntentFromCurrentUrl }
      } = dataStores;
      saveGameIntentFromCurrentUrl();
    } catch (e) {
      console.error('Failed to save game intent from current url', e);
    }
  }, []);

  return (
    <li className='signup-button-container'>
      <Link
        onClick={handleSignupClick}
        url={getSignupUrl(isAccountSwitchingEnabledForBrowser)}
        id='sign-up-button'
        className='rbx-navbar-signup btn-growth-sm nav-menu-title signup-button'>
        {translate('Label.sSignUp')}
      </Link>
    </li>
  );
}
HeaderSignupLink.propTypes = {
  translate: PropTypes.func.isRequired
};

export default HeaderSignupLink;
