import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { authenticatedUser } from 'header-scripts';
import { Link } from 'react-style-guide';
import { paymentFlowAnalyticsService } from 'core-roblox-utilities';
import { withTranslations } from 'react-utilities';
import links from '../constants/linkConstants';
import { translationConfig } from '../translation.config';
import navigationService from '../services/navigationService';
import LeaveRobloxPopupDisclaimer from '../components/robux-popover/LeaveRobloxPopupDisclaimer';

const { buyRobuxUrl } = links;
const guacMetadataCache = new Map();

function NavigationRobux({ translate, ...props }) {
  const { isAuthenticated } = authenticatedUser;
  const [isEligibleForVng, setIsEligibleForVng] = useState(false);
  const [isBuyRobuxOnExternalPartnerModalOpen, setIsBuyRobuxOnExternalPartnerModalOpen] = useState(
    false
  );

  const sendViewMessageEvent = viewMessage => {
    paymentFlowAnalyticsService.sendUserPurchaseFlowEvent(
      paymentFlowAnalyticsService.ENUM_TRIGGERING_CONTEXT.WEB_ROBUX_PURCHASE,
      false,
      paymentFlowAnalyticsService.ENUM_VIEW_NAME.NAVIGATION_ROBUX_TEXT,
      paymentFlowAnalyticsService.ENUM_PURCHASE_EVENT_TYPE.USER_INPUT,
      viewMessage
    );
  };
  const onBuyRobuxOnExternalPartnerClicked = () => {
    sendViewMessageEvent(paymentFlowAnalyticsService.ENUM_VIEW_MESSAGE.EXTERNAL_LINK_MODAL);
    setIsBuyRobuxOnExternalPartnerModalOpen(true);
  };
  const closeBuyRobuxOnExternalPartnerModal = () => {
    setIsBuyRobuxOnExternalPartnerModalOpen(false);
  };
  const onBuyRobuxOnExternalPartnerModalContinue = () => {
    sendViewMessageEvent(paymentFlowAnalyticsService.ENUM_VIEW_MESSAGE.CONTINUE_TO_VNG);
    const decodedUrl = decodeURIComponent(buyRobuxUrl.buyRobuxOnVng.url);
    window.open(decodedUrl, '_blank').focus();
    setIsBuyRobuxOnExternalPartnerModalOpen(false);
  };

  useEffect(() => {
    if (guacMetadataCache.has(buyRobuxUrl.buyRobuxOnVng.cacheKey)) {
      setIsEligibleForVng(guacMetadataCache.get(buyRobuxUrl.buyRobuxOnVng.cacheKey));
    } else {
      navigationService.getVngGuac().then(({ data: guacMetadata }) => {
        setIsEligibleForVng(guacMetadata.shouldShowVng);
        guacMetadataCache.set(buyRobuxUrl.buyRobuxOnVng.cacheKey, guacMetadata.shouldShowVng);
      });
    }
  }, []);

  const onBuyRobuxClicked = () =>
    sendViewMessageEvent(paymentFlowAnalyticsService.ENUM_VIEW_MESSAGE.BUY_ROBUX);

  return (
    <div>
      <LeaveRobloxPopupDisclaimer
        {...{
          isRedirectToExternalPartnerModalOpen: isBuyRobuxOnExternalPartnerModalOpen,
          closeBuyRobuxOnExternalPartnerModal,
          onBuyRobuxOnExternalPartnerModalContinue
        }}
      />
      {isEligibleForVng ? (
        <Link
          cssClasses='font-header-2 nav-menu-title text-header robux-menu-btn'
          onClick={onBuyRobuxOnExternalPartnerClicked}>
          {translate(buyRobuxUrl.buyRobux.name)}
        </Link>
      ) : (
        <Link
          cssClasses='font-header-2 nav-menu-title text-header robux-menu-btn'
          url={buyRobuxUrl.buyRobux.url}
          onClick={onBuyRobuxClicked}>
          {translate(buyRobuxUrl.buyRobux.name)}
        </Link>
      )}
    </div>
  );
}

NavigationRobux.propTypes = {
  translate: PropTypes.func.isRequired
};

export default withTranslations(NavigationRobux, translationConfig);
