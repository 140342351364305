import { Endpoints, CurrentUser } from 'Roblox';
import { getNavigationContainer } from './searchConstants';

const { getAbsoluteUrl } = Endpoints;

/** Looks for `data-safety-support-item-rollout="<num>"` set by web-platform to determine
 * the rollout of this new link.
 * TODO: [future] Once fully rolled out we can remove this logic (abech)
 */
export const isSafetySupportVisible = (): boolean => {
  const percentageStr = getNavigationContainer()?.dataset.safetySupportItemRollout || '0';
  const percentage = parseInt(percentageStr, 10);
  if (!Number.isFinite(percentage) || percentage <= 0) {
    return false;
  }

  const userId = parseInt(CurrentUser.userId, 10);
  if (!Number.isFinite(userId)) {
    return false;
  }
  /**
   * We have a pattern of using `userId % 100 < percentage` to determine if a user is in a rollout.
   * This however, always targets users in the same order which can be unfair. So we'll add an offset
   * here to target a different set of users first. This is an arbitrary/random value,
   * first value from Math.random() * 100 call */
  const offset = 67;
  return (userId + offset) % 100 < percentage;
};

export const safetySupportPageUrl = getAbsoluteUrl('/help-safety');
